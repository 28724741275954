.raceSchedule {
  &_practice {
    padding-right: 10px;
  }
  &_race {
    padding-left: 10px;
    position: relative;
    &:before {
      background-color: #dee2e6;
      width: 1px;
      height: 100%;
      top: 0;
      left: 0;
      content: '';
      position: absolute;
    }
  }
}

@media screen and (max-width: 575px) {
  .raceSchedule {
    &_practice {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }
    &_race {
      display: flex;
      justify-content: space-around;
      padding: 10px 0;
      p {
        margin-bottom: 0;
      }
      &:before {
        width: 100%;
        height: 1px;
      }
    }
  }
}