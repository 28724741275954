@import "~bootstrap/scss/bootstrap";

.container {
  padding: 0 30px;
}

.btn-contained {
  &:hover {
    color: #fff;
  }
}

// a {
//   &:hover {
//     color: #fff;
//   }
// }